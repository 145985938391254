.addButton {
    padding: 10px 30px !important;
}

.error-category-um {
    color: red;
    margin-top: 5px;
}


.img-error-category-um {
    color: red;
    margin-top: 5px;
    font-size: 14px;
    word-break: break-word;
}