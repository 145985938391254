.in-app-purchase table tr td {
    cursor: pointer;
}

.ant-modal-body {
   /* padding: 50px 24px 24px 24px ; */
   padding: 23px 24px 0px 24px;

}
.ant-modal-header{
    padding : 26px 24px 16px 24px !important ;
}
