.logo-container{
    padding: 8px;
    width: 100%;
    height: 100%;
    max-width: 50%;
    border-radius: 8px;
}
.logo-container img {
    width: 100%;
    height: 100%;
}
.logo-container-wrapper{
    
    width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
}