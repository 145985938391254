@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "../themes/colorConstant.css";

body {
  font-family: "Roboto", sans-serif !important;
}

.ant-table-content::-webkit-scrollbar {
  height: 5px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #fff; */
  background: #1b5449;
}

::-webkit-scrollbar-track {
  background: transparent;
}

audio::-webkit-media-controls-panel {
  background-color: #f1f1f1;
  color: #000;
}

section.ant-layout.ant-layout-has-sider .ant-layout-sider {
  z-index: 99;
}

.content-e {
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  /* border: solid 2px #d3d3d3; */
  /* d3d3d3 */
  border-radius: 0.35rem;
  /* box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; */
}

.ant-table table {
  background-color: #fff;
}

.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3 {
  margin-bottom: 4px;
  /* border : solid 2px #c4c4c4 ; */
  border-radius: 10px;
  /* padding: 35px 20px 35px 13px; */
  padding: 30px 30px;
  /* display: flex;
    align-items: center; */
  /* padding: 12px 13px 0; */
  word-break: break-all;
}

.ant-table-thead>tr>th {
  background: #f8f9fc !important;
}

.content-e {
  border-radius: 8px;
}

h6.text-white.text-capitalize.ps-3 {
  font-weight: 400;
  font-size: 18px;
  text-transform: capitalize;
  /* margin-bottom: 24px; */
  font-weight: 500;
  /* color: #161515; */
  color: #1b5449;
}

.mt-title {
  margin-bottom: 0px !important;
}

.ant-table-wrapper tr td button {
  border: 0;
  margin: 3px;
  color: #fff !important;
  cursor: pointer;
  border-style: solid;
  border-radius: 13px;
  border-radius: 8px;
  padding: 6px 6px;
  min-width: 75px;
  cursor: pointer;
}

.ant-breadcrumb {
  border-bottom: 1px solid #000;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
}

button.button-list {
  border: 0;
  margin: 3px;
  margin-left: 0px;
  color: #fff !important;
  padding: 10px 10px;
  cursor: pointer;
  border-style: solid;
  background-color: var(--appTheme);
  border-radius: 3px;
  border-radius: 8px;
}

.buttonList1 {
  border: 0;
  margin: 3px;
  color: var(--appTheme) !important;
  padding: 5px 10px;
  cursor: pointer;
  border-style: solid;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 8px;
  width: 65px;
  justify-content: center;
  display: flex;
  height: 35px;
  border: 2px solid var(--appTheme);
}

.buttonList2 {
  border: 0;
  margin: 3px;
  color: #fff !important;
  padding: 5px 10px;
  cursor: pointer;
  border-style: solid;
  background-color: var(--appTheme);
  border-radius: 3px;
  border-radius: 8px;
  width: 65px;
  justify-content: center;
  display: flex;
  height: 35px;
  border: 2px solid var(--appTheme);
}

.wrapper_line p {
  line-height: 40px;
}

/* element.style {
  margin: 24px 16px 0px;
} */

main.ant-layout-content.chat_new_one {
  margin-top: 14px !important;
}

.new-time {
  border-bottom: 1px solid #ccc !important;
  border: 0;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.new-time12:before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: red;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  transition: 0.5s;
}

.new-time12.time:before {
  transform: scaleX(1);
  transition: 0.5s;
}

.new-time {
  width: 100%;
  transform: 0.5s;
}

.new-time12 {
  position: relative;
}

.ant-table img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
  /* border: 2px solid #a6a8ef; */
}

input.form-control,
.wrapper_line p {
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 3px;
  padding: 4px 19px;
  width: 100%;
  height: 45px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
}

button.button-list {
  width: 100% !important;
  font-weight: 600;
  min-width: max-content;
}

.form-group label {
  font-weight: 500;
}

.line_true {
  width: 400px;
  margin: auto;
  position: relative;
  z-index: 99;
}

/* .image-bg {
  height: auto;
  min-height: 100vh;
} */

.page-header {
  background-size: cover !important;
  background: url("../images/Login bg.svg") no-repeat;
  display: flex !important;
  color: #fff;
  min-height: 100vh;

  /* height: 100%;
  width: 100%;
  min-height: 100vh;
  max-height: 1000px;
  background-image: url("../images/Login bg.svg"); 
  display: flex !important;
  color: #fff;
  background-size: cover; */
}

/* .page-header:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0; 
  content: "";
} */

.forrgot {
  color: #2e8f81 !important;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
}

h6.line_element a {
  color: #fff;
  margin-right: 10px;
}

.ant-drawer-content-wrapper ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  height: calc(100vh - 300px);
  background: transparent;
}

.ant-drawer-body {
  padding: 0 !important;
  overflow: initial !important;
}

.ant-drawer-content-wrapper aside.ant-layout-sider.ant-layout-sider-dark {
  /* transition: all 0.2s; */
  height: 100%;
  background-image: linear-gradient(195deg, #42424a, #191919);
}

.iocns {
  margin-right: 28px;
  font-size: 18px;
  padding-top: 21px;
  padding-left: 40px;
}

.top_button_item {
  padding: 20px 25px 0px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

textarea.text_line {
  width: 100%;
  height: 90px;
  border: 0;
  color: #6a6969;
  border-bottom: 1px solid #ccc;
  resize: none;
}

.outer-circle {
  width: 100px;
  height: 100px;
  background-color: #efefef;
  border-radius: 100%;
  text-align: center;
  margin: auto;
}

.upload-image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  cursor: pointer;
}

img.line_item_one {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ant-table-content {
  overflow: auto !important;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

/* th.ant-table-cell:last-child {
  text-align: center;
}

td.ant-table-cell:last-child {
  text-align: center;
  white-space: nowrap;
} */

input.search-bar {
  max-width: 237px;
  width: 100%;
  padding: 0px 8px 0px 27px;
  background-color: #ffffff;
  border-radius: 13px;
  color: #66615b;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  height: 42px;
  box-shadow: none;
  border: 1px solid var(--appTheme);
}

.system-line {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

button.ant-drawer-close {
  left: 0;
  background-color: #5dc0b0;
  padding: 7px 5px 8px 8px;
  text-align: center;
}

.iocns {
  max-width: fit-content;
}

main.ant-layout-content {
  margin-top: 0 !important;
  background-color: #f4f3ef;
}

.ant-breadcrumb {
  border: 0;
  padding: 11px 34px 0px 24px !important;
}

span.ant-breadcrumb-link,
.span.ant-breadcrumb-separator {
  color: #1b5449;
}

.ant-breadcrumb span {
  font-size: 15px;
  font-weight: 500;
}

.ant-breadcrumb a:hover {
  color: #6ed2f6 !important;
}

.ant-drawer.ant-drawer-right.ant-drawer-open {
  display: none;
}

body.ant-scrolling-effect {
  overflow: auto !important;
  width: calc(100% - 0px) !important;
}

.text-center.model1 button {
  border: 0;
  background-color: #2e8f81;
  border: 0;
  margin: 3px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  color: #fff !important;
  padding: 4px 6px;
  width: 60px;
}

.text-center.model1 p {
  color: #000;
  font-size: 17px;
}

.text-center.model1 {
  text-align: center;
}

/* .ant-modal-header {
    background-color: #6ed3f7 !important;
} */

/* .ant-modal-title{
    visibility: hidden;
} */

/* .ant-btn-primary {
  border-color: var(--appTheme) !important;
  background-color: var(--appTheme) !important;
} */
.ant-btn-primary {
  border-color: #1b5449 !important;
  background-color: #1b5449 !important;
}

/* .ant-modal-title {
    color: #fff !important;
} */

.ant-modal-content {
  border-radius: 6px !important;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--appTheme) !important;
  color: var(--appTheme) !important;
}

.new-button button.ant-btn.ant-btn-primary {
  color: #fff !important;
  width: 65px !important;
}

.ant-btn {
  border: none !important;
}


.ant-btn.ant-btn-default {
  /* height: 33.5px !important; */
  width: 65px !important;
  background-color: #1b5449 !important;
  color: white !important;
}

.ant-pagination-item-active a {
  color: #1b5449 !important;
}

.ant-pagination-item-active {
  border-color: #6ed3f7 !important;
}

.ant-table-pagination.ant-pagination {
  margin-right: 10px !important;
}

.ant-breadcrumb>span:last-child a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}

.table-img {
  border-radius: 45px;
  border: 3px solid #000;
}

img.plus_icon {
  width: 30px !important;
  position: absolute;
  bottom: 0;
  height: unset !important;
  right: 5px;
  top: 50px;
  background: white;
  cursor: pointer;
  border-radius: 30px;
}

section.ant-layout.new_layout {
  height: 100%;
  background-color: #ffffff !important;
}

body {
  background-color: #f4f3ef !important;
}

.logo {
  /* margin: 0px 11px 13px; */
  margin: 0;
  /* border-bottom: 1px solid rgba(102, 97, 91, 0.3); */
  border-bottom: 1px solid rgb(255 255 255 / 30%);
  padding-bottom: 17px;
}

.wrapper_line label {
  font-weight: 600;
  font-size: 15px;
  max-width: 97px;
  width: 100%;
}

.button_left {
  display: inline-block;
}

.ant-space.ant-space-vertical {
  float: right;
}

.menu_top button.button-list {
  margin-top: 0;
  margin-bottom: 22px;
  margin-right: 0px;
}

.menu_top {
  margin-left: 37px;
}

textarea.form-control.data {
  height: 209px;
  width: 100%;
}

.right_chat {
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  min-width: 200px;
  max-width: 200px;
  height: calc(100vh - 200px);
  display: inline-block;
  overflow: hidden;
}

.right_column {
  float: right;
  display: inline-block;
  width: calc(100% - 220px);
}

.message-content {
  padding: 1rem 2.25rem;
  background-color: #f5f6fa;
  color: #8094ae;
  margin-left: 1.25rem;
  border-radius: 1.25rem;
  text-align: left;
  display: inline-block;
  max-width: 25rem;
  border: 3px solid #0c8372;
}

.imge_wrappe.active span {
  color: #fff !important;
  opacity: 1;
}

.active_user .message-content h6.text-dark {
  font-size: 17px;
  color: #fff;
}

.message-content h6 {
  font-size: 17px;
}

.message-options {
  text-align: right;
}

.avatar.avatar-sm img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem #fff;
}

.active_user .message-content {
  color: #fff;
  background-color: #383838;
  border: 3px solid #0c8372;
}

.active_user.wrapper_cli {
  text-align: right;
}

.message-options {
  text-align: left;
}

.active_user .message-options {
  text-align: right;
}

.footer {
  background-color: #fff;
  padding: 12px 29px;
}

button.button-list.data_R {
  margin-top: 0px;
  margin-bottom: 13px;
}

.home-chat {
  /* min-height: calc(100vh - 200px); */
  height: calc(100vh - 258px);
  overflow: auto;
  background-color: #fff;
  padding: 18px 9px;
}

.right_chat ul {
  padding-left: 0;
}

.right_chat ul li {
  padding: 8px 25px;
}

.right_chat ul li img {
  width: 30px;
  height: 30px;
}

.user-status {
  width: 10px;
  height: 10px;
  background-color: #adb5bd;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  left: auto;
  bottom: 0;
  background: red;
}

.item_t {
  position: relative;
  display: inline-block;
}

.imge_wrappe span {
  font-size: 14px;
  color: #5a636c;
  transition: all 0.4s;
  font-size: 14px;
  margin-left: 9px;
}

.imge_wrappe.active {
  border-color: rgb(10, 127, 110);
  background-image: -webkit-linear-gradient(-109deg,
      rgba(57, 182, 164, 0.99608) 0%,
      rgb(2, 105, 90) 100%);
  /* border: 3px solid #0c8372; */
}

.right_chat ul li {
  margin-bottom: 12px;
  padding: 0px 0;
}

.imge_wrappe {
  padding: 8px 9px;
}

.chat_point textarea {
  height: 72px;
  resize: none;
  width: 87%;
  border: 3px solid #0c8372;
}

.text-details.header {
  height: 65px;
  width: 100%;
  background-color: #383838;
}

.text-details.header .item_t img {
  width: 50px;
  height: 50px;
  margin-top: 6px;
  margin-left: 7px;
}

.lite_ew {
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
}

.lite_ew h4 {
  font-size: 14px;
  margin-bottom: 0;
  color: #fff;
}

.lite_ew p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
}

.imge_wrappe.active span {
  color: #fff;
}

.chat_point {
  display: initial;
}

.footer button.button-list.top_new {
  float: right;
  vertical-align: super;
}

button.button-list.active {
  background-color: #000;
}

.menu_top {
  margin-top: 20px;
}

.top_button_item.result {
  padding: 0px 23px 0;
}

.text_new_one {
  margin-bottom: 20px;
}

.ant-tabs-nav {
  padding: 9px 25px;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
  padding-bottom: 12px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1b5449 !important;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
  background: #1b5449 !important;
}

svg.svg-inline--fa.fa-arrow-left.fa-w-14 {
  color: #000;
  font-size: 18px;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.MuiFormControl-root.css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}

.top_button_item.top_text {
  padding-top: 0;
}

.category_d {
  margin-top: 18px;
  padding-left: 24px;
}

.true_legend {
  display: flex;
  flex-direction: column;
}

.main_image {
  width: 200px;
  height: 200px;
}

.iamge-t img:first-child {
  width: 100%;
  height: 136px;
  position: relative;
}

img.cross-i {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -13px;
  right: -9px;
  cursor: pointer;
}

.iamge-t {
  position: relative;
}

.wrapper_multi {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 12px;
}

.content-e {
  margin-top: 0px;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #7289d2;
}

.form-group.category {
  padding-left: 26px;
}

.text-center_login {
  text-align: center;
}

.image-bg h6.text-white.text-capitalize.ps-3.text-center {
  text-align: center;
  width: 100%;
}

p.option_lite {
  font-size: 17px;
  margin-bottom: 0;
  color: #666;
  font-weight: 600;
}

.ant-table-thead>tr>th {
  font-weight: 600 !important;
}

p.max {
  padding-left: 26px;
  padding-top: 6px;
  margin-bottom: 0;
}

.video-div {
  position: relative;
  max-width: 100%;
  display: flex;
  width: 322px;
}

p.option_lite.f {
  border: 0;
  font-size: 15px;
  padding: 0;
}

.forrgot a:hover {
  color: #b4b0f7;
}

p.text-sm.mb-0 {
  color: #999999;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after,
.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  border-bottom: 2px solid #9da3ea !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #9da3ea !important;
}

span.font_spacing {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-group.time-picker input#mui-3 {
  cursor: pointer;
}

button.item_interests {
  border: 0;
  background: linear-gradient(180deg, #b4b0f7 0%, #7289d2 100%) !important;
  border-radius: 3px;
  box-shadow: 0 12px 20px -10px rgb(176 174 245),
    0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(122 142 215);
  border-radius: 8px;
  padding: 4px 6px;
  margin: 4px;
  cursor: pointer;
}

p.option_lite.f.small_text {
  font-size: 14px;
  font-weight: 400;
}

button.item_interests:nth-child(2) {
  border: 3px solid #fff;
}

.view_modules button.item_interests:nth-child(2) {
  border: 0px solid #fff;
}

.view_modules button.item_interests {
  cursor: unset;
}

.wrapper_multi.space_l {
  margin-bottom: 13px;
}

section.ant-layout.new_layout.data {
  max-width: calc(100% - 108px);
}

.datacenter {
  width: 100px;
  margin: auto;
  position: relative;
  margin-bottom: 16px;
}

.datacenter-icon {
  position: absolute;
  right: 3px;
  top: 73px;
  cursor: pointer;
}

.event-b {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0px 12px;
}

.event-b h2 {
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.hide {
  visibility: hidden;
}

.ong-event {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.ong-event-to {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 16px;
}

.ong-event-to-found {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 14px 16px;
}

.images h2 {
  color: black;
  font-size: 23px;
  font-weight: 600;
}

.img-slider {
  text-align: center;
}

.winner h2 {
  padding-left: 20px;
  font-size: 24px;
  margin: 0px;
  font-weight: 600;
  color: black;
}

.form-control {
  width: 100%;
  padding: 13px !important;
}

.form-group {
  margin-bottom: 23px;
  /* display: flex; */
  align-items: center;
  height: 68px;
}

.up-btn {
  text-align: center;
}

.update-text {
  font-weight: 500;
  text-align: center;
  font-size: 15px;
}

.success-deta {
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-modal-footer {
  border-top: 0 !important;
}

.forgot_text {
  padding: 4px 14px;
  border-radius: 3px;
  background: white;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-color: #d9d9d9;
  background: #fff;
}

.forgot-text-btn {
  text-align: right;
}

/* .ant-modal-close-x {
    display: block;
    width: 29px !important;
    height: 27px !important;
    font-size: 16px;
    font-style: normal;
    line-height: 31px !important;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    background: black;
    color: white;
    border-radius: 16px;
} */

.slick-slider img {
  width: 100%;
  height: 200px;
  padding: 9px;
}

.heading-event h2 {
  color: black;
  font-size: 16px;
  padding: 13px;
  font-weight: 600;
}

.Slider {
  padding: 6px 24px 31px 24px;
}

.main-div {
  margin: auto;
  /* z-index: 99;
  display: flex;
  flex-wrap: wrap; */
}

.bg-login-image {
  z-index: 99;
}

.bg-login-image img {
  width: 100%;
  height: 562px;
  border-radius: 8px 0px 0px 8px;
}

.login-form {
  /* border: 1px solid #cccccc; */
  max-width: 418px;
  min-width: 363px;
  min-height: 440px;
  height: auto;
  border-radius: 17px;
  background-color: #fff;
  text-align: center;
}

.login-form h3 {
  margin: 24px 0px 24px 0px;
  font-size: 26px;
  font-weight: 500;
}

.login-form h4 {
  margin: 23px 0px 24px 0px;
  font-size: 26px;
  font-weight: 500;
}

.user input {
  width: 91%;
  font-size: 1rem;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  outline: none;
  font-weight: 400;
  line-height: 0.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
}

.custom-checkbox {
  text-align: start;
  margin-bottom: 20px;
  display: flex;
}

.custom-checkbox label {
  color: #6e707e;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.custom-checkbox input {
  margin-right: 5px;
  margin-left: 24px;
}

/* .login-link a {
    border-radius: 20px;

    border-radius: 20px;
    background-color: var(--appTheme);
    border-color: var(--appTheme);
    padding: 12px;
    width: 90%;
    margin: auto;
    max-width: 396px;
    display: inline-block;
    margin-bottom: 31px;
} */
.login-link button {
  border-radius: 8px;
  background-color: var(--appTheme);
  border-color: var(--appTheme);
  padding: 12px;
  width: 90%;
  margin: auto;
  max-width: 396px;
  display: inline-block;
  margin-bottom: 31px;
  border-bottom: var(--appTheme);
  border-right: var(--appTheme);
  border-top: var(--appTheme);
  border-left: var(--appTheme);
}

.login-link a {
  color: white;
}

.login-link a:hover {
  color: white;
}

.forgot-link {
  font-size: 14px;
  margin-bottom: 25px;
  font-weight: 400;
  margin-top: 12px;
  padding-bottom: 30px;
}

.back-link {
  border-radius: 20px;
  background-color: #4e73df;
  border-color: #4e73df;
  padding: 12px;
  width: 89%;
  margin: auto;
  margin-top: 20px;
}

.back-link a {
  color: white;
}

.back-link a:hover {
  color: white;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  background-color: transparent;
  /* overflow-y: scroll; */
  /* height: 400px;  */
  height: 100%;
}

.angle-btn {
  text-align: center;
  margin-top: 20px;
  color: white;
  border: none;
  display: none;
  justify-content: center;
}

.btn:hover {
  background-color: #1b5449 !important;
  color: #fff;
  transform: scale(1.2);
}

.btn {
  height: 36px;
  padding: 4px;
  border: none;
  border-radius: 50px;
  width: 36px !important;
  color: rgba(255, 255, 255, 0.5);
  background-color: #6f9591c9 !important;
  /* background-color: hsl(0deg 0% 60% / 20%) !important; */
  /* box-shadow: 4px 4px 5px 0px rgb(68 68 68 / 75%); */
  display: flex;
  justify-content: center;
  align-items: center;
}

svg.svg-inline--fa.fa-bars.fa-w-14.lite-text {
  display: block;
}

.login-link.back_mr {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}

.site-layout-background.dashboard {
  padding-top: 1px !important;
}

.site-layout-background.dashboard .ant-breadcrumb {
  padding: 0px 34px 0px 2px !important;
  border: 0;
}

.sider_left.new.closedd span.line_dash {
  display: none;
}

.logo img {
  max-width: 66%;
}

.sider_left.new.closedd .logo {
  margin: 0;
}

.sider_left.new.closedd span.ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-thead>tr>th {
  white-space: nowrap;
}

.ant-modal-close {
  top: -10px !important;
  right: -7px !important;
}

/* .ant-modal-close-x {
    display: none !important;
} */

.deta-first-model p {
  padding: 26px 0;
  margin-bottom: 0px;
}

.deta-first-model a {
  margin-right: 10px;
}

.forgot-bg {
  background: linear-gradient(180deg, #57d5f7 0%, #34c6ee 100%);
  padding: 4px 14px;
  border-radius: 3px;
  border: none;
  color: white;
  font-size: 16px;
}

button {
  cursor: pointer;
}

.slick-next {
  right: -15px !important;
}

.slick-prev {
  left: -18px !important;
}

.slick-next:before {
  font-size: 24px !important;
  color: black !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px !important;
  color: black !important;
}

.datacenter-vector {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  top: -4px;
  right: -6px;
  z-index: 99;
  cursor: pointer;
}

.iocn-search {
  background-image: url("../images/search.png");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 4% 50%;
}

.ant-modal-content {
  width: 416px !important;
  margin: auto !important;
  padding: 11px 7px;
}

.deta-first-model .ant-modal-body {
  padding-bottom: 0 !important;
}

.deta-first-model .ant-modal-content {
  padding: 20px 12px 30px;
}

.forgot-text-btn {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

button.forgot_text:hover {
  border-color: #6ed3f7 !important;
  color: #6ed3f7 !important;
}

.forgot-link a:hover {
  color: #1b5449;
  font-weight: 700;
}

input#vehicle1 {
  cursor: pointer;
}

.mage {
  margin-left: 7px !important;
}

.forgot-text-btn a {
  margin: auto !important;
}

.ant-space.ant-space-vertical {
  width: 100% !important;
}

.ant-picker {
  width: 100% !important;
}

.ant-picker-input {
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  /* border-radius: 31px; */
  padding: 4px 19px;
  width: 100%;
  height: 51px;
}

.ant-picker {
  border: none !important;
  padding: 0px !important;
  outline: none !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.pading {
  padding: 24px 0px 24px 0px !important;
}

.user input::placeholder {
  color: #6e707e !important;
}

input.form-control::placeholder {
  color: #6e707e;
}

.btn2 {
  width: 36px !important;
  height: 36px;
  background-color: var(--appTheme);
  /* color: rgba(255,255,255,.5); */
  padding: 4px;
  color: #fff;
  font-size: 23px;
  line-height: 13px;
  border: none;
  border-radius: 50px;
}

.back {
  /* margin-top: 20px;
  display: inline-block;
  width: 9%;
  position: absolute;
  top: 12px;
  left: 17px; */
  padding: 12px 17px 0;
  display: flex;
}

.displ {
  display: none;
}

.ant-picker-input>input::placeholder {
  color: #6e707e !important;
}

.ant-picker-input input {
  cursor: pointer !important;
}

.ant-picker-input {
  cursor: pointer !important;
}

.ant-select {
  color: #242425 !important;
  width: 100% !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* border-radius: 31px !important; */
  /* padding: 10px !important; */
  /* height: 51px !important; */
  height: 45px !important;
  margin-top: 5px;
}

span.ant-select-arrow {
  color: #000 !important;
  margin-right: 6px;
}

.login-form img {
  /* margin-top: 24px; */
}

.ant-calendar-input-wrap {
  display: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #d1d3e2 !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d1d3e2 !important;
  border-right-width: 1px !important;
}

input:disabled {
  background-color: rgb(226, 226, 226);
}

.ant-picker-dis .ant-picker-input {
  background: rgb(226, 226, 226) !important;
}

.ant-picker-dis .ant-picker-input>input[disabled] {
  background: transparent;
  cursor: default !important;
}

.ant-picker-dis .ant-picker-input {
  background: rgb(226, 226, 226) !important;
  cursor: default !important;
}

.ant-layout-sider-children {
  height: 100vh !important;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

.ant-tabs-tab:hover {
  color: var(--appTheme) !important;
}

.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: default !important;
}

.main-div-2.dashboard {
  grid-gap: 38px;
  gap: 38px;
}

.user-valid-text {
  /* max-width: 96%; */
  width: 100%;
  min-width: 96%;
  text-align: center;
  border-radius: 10px;
  height: auto;
  border: 1px solid var(--appTheme);
}

.main-div-2 {
  margin: auto;
  padding-top: 5px;
  z-index: 99;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: auto auto;
  background-color: #fff;
}

.main-div-2 .datacenter img {
  padding-top: 18px;
}

.user-text p {
  font-size: 17px;
  padding: 12px 12px 12px 27px;
  /* margin-top: 10px; */
  font-weight: 600;
  color: #606078;
  text-align: left;
}

button.pass-gen {
  width: 117px !important;
}

.primiss-tb {
  background: white;
  padding: 20px;
  margin: 70px 1px 24px 1px;
  border-radius: 9px;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  overflow: auto;
}

.primiss-tb h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.primiss-tb table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.primiss-tb table td,
th {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  height: 57px;
}

.primiss-tb table tr td input {
  width: 17px;
  height: 18px;
}

.primiss-tb table tr:nth-child(even) {
  background-color: #dddddd;
}

.datacenter-2 {
  margin: auto;
  position: relative;
  width: 100px;
  margin-bottom: 28px;
}

.about-us {
  padding: 12px;
}

.about-us p {
  line-height: 29px;
  font-size: 15px;
  font-weight: 400;
}

.mt-top img {
  margin-top: 89px;
}

.about-us textarea {
  width: 100%;
  border: 1px solid #dddddd;
  height: 203px;
  border-radius: 9px;
  resize: none;
  padding: 10px;
}

.input_fields {
  flex: 1;
}

.fields {
  /* display: flex; */
  align-items: center;
}

.image-fle {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  padding: 0px 22px;
}

.total-num h1 {
  font-size: 46px;
  margin-top: 11px;
  color: #606078;
  font-weight: 500;
}

.up-cent {
  text-align: left;
}

.up-cent button {
  width: 15% !important;
}

.key textarea {
  width: 100%;
  border: 1px solid #dddddd;
  height: 47px;
  border-radius: 9px;
  resize: none;
  padding: 10px;
  line-height: 24px;
}

.key {
  padding: 10px 13px 6px 13px;
}

.admin-text h2 {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.ant-table-tbody>tr>td {
  max-width: 346px;
}

.heading-h h2 {
  font-size: 19px;
  font-weight: 600;
  color: #66615b;
  margin: 22px 0px;
}

.flex {
  display: flex;
}

.mt-topp {
  margin-top: 20px;
}

button.button-list.addturna {
  max-width: 75px;
}

.forgot-link a {
  /* color: #42453f; */
  color: #1b5449;
  font-weight: 500;
}

.ant-breadcrumb a {
  color: #9a9a9a !important;
}

.ant-breadcrumb a:hover {
  color: #1b5449 !important;
}

.site-layout-background,
.ant-breadcrumb {
  background: #ffffff;
}

.ant-pagination-item-active {
  border-color: var(--appTheme) !important;
}

select {
  color: #9e9e9e;
}

option:not(:first-of-type) {
  color: black;
}

.btn-col {
  background-color: #3a4530 !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 21px !important;
  text-align: right;
  margin-bottom: 7px;
}

.ant-picker-clear {
  right: 2px !important;
}

.login-link a:focus-visible {
  border: black 1px solid;
}

.forgot-link a:focus-visible {
  border: black 1px solid;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-left: 2px !important;
}

.vote {
  margin: 41px 10px 41px 10px;
  padding: 18px;
  box-shadow: 1px 3px 11px 1px;
}

.images-sec {
  display: grid;
  gap: 40px;
  grid-template-columns: auto;
  padding: 10px 0px;
  column-gap: 32px;
}

.images-sec img {
  width: 100%;
  max-width: 278px;
  height: 230px;
}

.images-sec video {
  width: 100%;
  max-width: 284px;
  height: 162px;
}

.text-area textarea {
  width: 100%;
  max-width: 284px;
  height: 150px;
  padding: 11px;
  resize: none;
  margin-top: 12px;
  border: 2px solid gray !important;
  outline: none;
}

.heading-top-desk h1 {
  font-size: 22px;
  /* padding: 20px; */
  padding: 20px 0px 0px 21px;
  margin-left: 4px;
}

.input-tex-cat input {
  width: 29px;
  height: 19px;
}

.input-tex-cat {
  margin-top: 12px;
  display: flex;
}

.poll-heading h1 {
  font-size: 22px;
  font-weight: 600;
}

.images-sec audio {
  width: 100%;
  max-width: 284px;
}

.action-icon svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 33px;
  margin-right: 9px;
  color: var(--appTheme);
  cursor: pointer;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border: 0px !important;
}

.ant-tabs.ant-tabs-top {
  background: #ffffff;
}

.graph {
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 286px;
}

.days span {
  font-size: 14px;
  color: #606078;
  text-align: center;
  margin-top: 10px;
}

.pie-graph {
  max-width: 350px;
  width: 100%;
  margin: auto;
}

.Pie_chart_container {
  margin: 20px;
  text-align: center;
}

.pie-chart {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.mg-bott {
  margin-bottom: 30px;
}

.login-btn {
  background: transparent;
  border: none;
}

.wrapper_line.view_modules.view .fields p {
  /* background: #e2e0e0; */
  background: #fefefe;
  border-radius: 8px;
}

span.anticon.anticon-calendar {
  color: black;
}

.ant-modal-confirm-body>.anticon {
  margin-right: 10px !important;
}

.max-count {
  text-align: right;
}

input[type="image"] {
  height: 100px !important;
  border-radius: 50px;
}

.action-icon {
  /* margin-left: 14px; */
  /* margin-right: 13px; */
  /* width: 330px; */

  min-width: 250px !important;
}

textarea#w3reviewbio {
  width: 100%;
  padding: 13px;
  resize: none;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
}

textarea.bio_textarea {
  width: 100%;
  padding: 13px;
  resize: none;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
}

span.ant-collapse-header-text {
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
}

.heading-tex h1 {
  font-size: 23px;
  font-weight: 500;
}

svg.svg-inline--fa.fa-bars.fa-w-14.lite-text {
  color: white;
  cursor: pointer;
}

.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
  max-width: 279px;
}

.error {
  color: red;
  display: flex;
  margin-left: 27px;
  margin-top: -16px;
  margin-bottom: 20px;
  margin-right: 25px;
}

.error1 {
  color: red;
  margin-top: -12px;
  margin-bottom: 14px;
}

span.anticon.anticon-eye svg,
span.anticon.anticon-stop svg,
span.anticon.anticon-edit svg,
span.anticon.anticon-delete svg {
  font-size: 30px;
  fill: var(--appTheme);
  margin: 5px;
}

div.ant-image-mask-info>span.anticon.anticon-eye svg {
  font-size: 17px;
  fill: var(--appTheme);
  margin: -1px;
}

.table-pagination {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 20px;
  /* margin-right: 23px; */
  padding: 0 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26afa4 !important;
  border-color: #26afa4 !important;
}

.ant-spin-dot-item {
  background-color: #26afa4 !important;
}

.Toastify__toast {
  padding-top: 4px !important;
  padding: 2px !important;
}

thead.ant-table-thead {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.line_dash {
  background-color: transparent !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  /* padding: 20px; */
  font-size: 30px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 140px;
}

@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid_checkbox {
  display: flex;
  margin-left: 8px;
  margin-top: 6px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26afa4 !important;
  border-color: #26afa4 !important;
}

.modall.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.grid_radio {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}

.ant-radio-inner::after {
  background-color: #26afa4 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--appTheme) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: var(--appTheme) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--appTheme) !important;
}

.ant-modal-header {
  display: flex;
  justify-content: center;
}

.background_image {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}

.background_image1 {
  display: flex;
  height: 100%;
  position: absolute;
  object-fit: cover;
  width: 100%;
}

.text_area {
  padding: 40px 13px 0px 12px;
  resize: none;
  font-size: 14px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-column-break-after: auto;
  break-after: auto;
  max-height: 110px;
  max-width: 240px;
  overflow: hidden;
  page-break-after: auto;
  white-space: break-spaces;
  background: white;
  border: none;
}

.text_area1 {
  padding: 24px 13px 0px 12px;
  font-size: 14px;
  resize: none;
}

.ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
}

.ant-modal.poll_modal1 {
  width: 950px !important;
}

.ant-modal.poll_modal_image {
  width: 700px !important;
}

.ant-checkbox-inner:after {
  width: 6px !important;
  height: 14px !important;
}

.plus_icon_new {
  position: absolute;
  top: 73px;
  left: 73px;
  cursor: pointer;
}

.upload_image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid var(--appTheme) !important;
  cursor: pointer;
}

.upload_image1 {
  width: 100%;
  height: 230px;
  border: 2px solid var(--appTheme) !important;
  cursor: pointer;
}

.ant-pagination-item:hover {
  border-color: #1b5449 !important;
}

.ant-pagination-item:hover a {
  color: #1b5449 !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1b5449 !important;
  border-color: #1b5449 !important;
  /* color: var(--appTheme) !important;
  border-color: var(--appTheme) !important; */
}

.display_center {
  display: flex;
  justify-content: center;
}

.ant-checkbox-group {
  width: 100% !important;
}

.ant-picker-clear {
  display: none !important;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  cursor: default !important;
}

.ant-image-img {
  height: 100px !important;
  width: 100px !important;
}

div.datacenter-2>div.ant-image>.ant-image-mask {
  border-radius: 50px;
}

img.ant-image-img.background_imageee {
  display: flex;
  height: 100% !important;
  position: absolute;
  width: 100% !important;
}

.grid-item>.ant-image {
  position: initial !important;
  display: block !important;
}

.audio_icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.hideElement {
  display: flex;
  align-items: center;
}

.play_pauseAudio {
  display: flex;
  justify-content: center;
  font-size: 40px;
  cursor: pointer;
}

.audio_icon:hover .hideElement svg.svg-inline--fa.fa-play-circle.fa-w-16 {
  color: var(--appTheme) !important;
}

.audio_icon:hover {
  background: rgba(0, 0, 0, 0.4);
}

.marginTop {
  margin-top: -67px;
  position: absolute;
  left: 80%;
  z-index: 999;
}

.flexDisplay {
  display: flex;
}

.wrapFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.vottes_picker>.ant-space.ant-space-vertical>.ant-space-item>.ant-picker>.ant-picker-input {
  height: 35px !important;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: var(--appTheme) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--appTheme) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--appTheme) !important;
}

img.ant-image-img.option_report_image {
  width: 280px !important;
  height: 250px !important;
}

.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 transparent !important;
}

.buttonList12 {
  border: 0;
  margin: 3px;
  color: #fff !important;
  padding: 5px 10px;
  cursor: pointer;
  border-style: solid;
  background-color: var(--appTheme);
  border-radius: 3px;
  border-radius: 8px;
  width: 65px;
  justify-content: center;
  display: flex;
  height: 35px;
  border: 2px solid var(--appTheme);
}

.buttonList11 {
  border: 0;
  margin: 3px;
  color: var(--appTheme) !important;
  padding: 5px 10px;
  cursor: pointer;
  border-style: solid;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 8px;
  width: 65px;
  justify-content: center;
  display: flex;
  height: 35px;
  border: 2px solid var(--appTheme);
}

.anticon-exclamation-circle svg {
  color: #1b5449;
}

/* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
    position: absolute !important;
} */
.coin-management-table .action-icon {
  margin-right: 15px !important;
}

.ride-management-table th.ant-table-cell:last-child,
.coin-management-table th.ant-table-cell:last-child {
  padding-right: 28px;
}

.user-management-table th.ant-table-cell:first-child,
.user-management-table tr td:first-child,
.coin-management-table th.ant-table-cell:first-child,
.coin-management-table tr td:first-child,
.ride-management-table th.ant-table-cell:first-child,
.ride-management-table tr td:first-child {
  padding-left: 31px !important;
}

.ride-management-table th.ant-table-cell:last-child,
.ride-management-table td.ant-table-cell:last-child {
  text-align: left;
}

.pre-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.admin-text {
  border-bottom: solid #608c85 1px;
  margin: 0 9px 10px 9px;
}

/* backgroud color */

/* .content-e {
  background-color : #8aa7a440
 }
 
 .site-layout-background, .ant-breadcrumb {
  background: #d7d7d8;
}
.ant-tabs.ant-tabs-top {
  background: #d7d7d8;
}
section.ant-layout.new_layout { 
  background-color: #d7d7d8 !important;
}
.ant-layout-header {
  box-shadow: -1px 0px 24px -4px rgb(0 0 0 / 43%) !important;
}
aside.ant-layout-sider.ant-layout-sider-dark.sider_left.new {
  box-shadow: 8px 1px 23px -4px rgb(0 0 0 / 18%);
} */
input[placeholder="Search"] {
  box-shadow: 0px 1px 23px -4px rgb(0 0 0 / 11%);
  border-radius: 8px !important;
}

.forgot-success button.button-list {
  width: 100% !important;
  font-weight: 600;
  padding: 10px 37px;
  max-width: max-content;
}

.forgot-success .ant-modal-close-x {
  display: none;
  cursor: none !important;
}

.forgot-success h1 {
  color: #1b5449;
}

input[type="color"] {
  border-color: transparent !important;
}

.ant-menu-item a::before {
  position: relative !important;
}

.ant-modal-title {
  font-size: 26px !important;
  color: #1b5449 !important;
}

/* .ant-table-tbody>tr>td {
  white-space: nowrap;
} */

.in-app-purchase td.ant-table-cell:last-child,
.in-app-purchase .ant-table-container table>thead>tr:first-child th:last-child {
  text-align: left !important;
}

.ant-select-selection__placeholder {
  color: red !important;
}

button.add-button {
  border: 0;
  margin: 3px;
  color: #fff !important;
  border-style: solid;
  border-radius: 8px;
  padding: 6px 6px !important;
  width: 75px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

button.submit-button {
  border: 0;
  margin: 3px;
  color: #fff !important;
  border-style: solid;
  border-radius: 8px;
  padding: 8px 14px !important;
  width: 109px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.flex-start {
  justify-content: flex-start;
}

/* .user-management-table th.ant-table-cell:last-child {
  width: 100%;
} */
.reset-logo {
  height: 100%;
  width: 50%;
  padding: 8px;
}

.rest-bg {
  height: 300px;
  padding: 25px;
  max-height: 300px;
  min-height: 282px;
}

.ant-pagination-item-active {
  border-color: #1b5449 !important;
}

img.Img-custom {
  border-radius: 50px;
}

.delete-all-button {
  width: 100px !important;
}

.table-tabs .ant-tabs-tab:hover,
.table-tabs .ant-tabs-tab:active,
.table-tabs .ant-tabs-tab:focus {
  color: #1b5449 !important;
}

.table-tabs .ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-remove:active {
  color: #1b5449 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}


.button-para .text-wrapper {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-table-action-wrapper {

  width: 330px;
}

.min-width-playTime {
  min-width: 115px !important;
}