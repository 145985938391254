.addButton {
    padding: 10px 30px !important;
}

.error-category-um {
    color: red;
    margin-top: 5px;
}

.red-background {
    background: #d93a3a !important;
}