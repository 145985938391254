.wrapper_line textarea,
.wrapper_line input {
    color: #000;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 8px;
    padding: 4px 19px;
    width: 100%;
    height: 45px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;

    margin: 0.5em 0px 1em;
    background: #fefefe;
    line-height: 40px;
}


.error-question-um {
    color: red;
    margin-top: 8px;
    min-height: 10px;

}

.margin-0 {
    margin: 0 !important;
}

.add-question-btn-wrapper {


    margin-top: 20px;
}


.question-table-question-col {

    width: 300px;
    word-break: break-word;
    height: 30px;
}

.question-table-question-col {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.add-question-auto-complete .ant-select-selector {
    padding-left: 19px !important;
    align-items: center;
}

.add-question-auto-complete .ant-select-selection-search {
    display: flex;
    align-items: center;
}

.add-question-auto-complete input.ant-select-selection-search-input {
    padding-left: 8px !important;
}

.add-question-auto-complete-dropdown .ant-select-item.ant-select-item-option {
    pointer-events: none !important;
}

.download-category-data-container table {
    width: 100%;
    margin-top: 20px;
}

.download-category-data-container table tr a {
    background-color: #1b5449;
    padding: 3px;
}

.download-category-data-container table tr td button {
    color: white;
}

.download-category-data-container table tr td:last-child {
    text-align: end;
}


.category-delete-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.category-delete-confirmation-container>div {
    align-items: center;
    display: flex;
}

.category-delete-confirmation-container>div>input {
    width: 15px;
    height: 15px;
}

.category-delete-confirmation-container>div>label {
    padding-left: 5px;
}

.category-delete-modal .ant-modal-header {
    border-bottom: 0;
    padding: 0 !important;
}

.category-delete-modal .ant-modal-title {
    width: 100%;
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px !important;
    line-height: 1.4;
}

.category-delete-modal .anticon-exclamation-circle {

    float: left;
    margin-right: 16px;
    font-size: 22px;
}

.category-delete-modal .ant-modal-content {
    padding: 43px 43px 31px !important;

}

.category-delete-modal .ant-modal-body {
    padding: 0 !important;
    margin-top: 8px !important;
    margin-left: 2px !important;
}

.category-delete-modal .ant-modal-footer {
    margin-top: 21px !important;
    text-align: right;
    margin-bottom: 7px;
    padding: 0;
}

.pagination-per-page {
    background: white;
    padding: 0 4px;
}

.table-pagination-with-per-page {

    display: flex;
    justify-content: space-between;
}

.pagination-limit-btn {
    background-color: white !important;
    color: #000 !important;
}

.pagination-limit-btn:hover,
.pagination-limit-btn:focus,
.pagination-limit-btn:active {
    background-color: white !important;
    color: #000 !important;
    border-color: #1890ff !important;
}


.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 0px !important;
    height: 0px !important;
    border: 0;
    opacity: 1;
    content: '';
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1b5449 !important;
    border-color: #1b5449 !important;
}

.button-para {
    display: flex;
    align-items: baseline;
}

.button-para p {
    font-weight: 500;
    font-size: 15px;
}

.for-player-answer {
    margin-top: -3.6% !important;
}