.user-tab {
    display: grid;
    grid: auto-flow dense / 1fr 1fr;
    gap: 2rem;
    padding: 35px 35px !important;
}
@media (max-width : 799px ) {
    .user-tab {
        grid: auto-flow dense / 1fr ;
    } 
}
.no-padding-margin {
    margin : 0 ;
    padding : 0 ;
}
.displayNone{
    display: none;
}
.card-wrapper {
    border-radius: 5px;
    padding: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff ;
     /* box-shadow: 0px 4px 20px rgb(2 2 2 / 14%); */
    color: #fff;
    border: solid 1px #1b544b;  
    min-height: 200px;
  }
.card-img-wrapper {
    margin-bottom: 10px;
    justify-content: center;
  }
  .card-description {
    margin: 0;
    font-size: 16px;
    color: #1b5449 !important;
    font-weight: 500;
    line-height: 18px;
  }
  @media (max-width : 1208px) {
      .card-wrapper {
          padding: 39px !important;
      }
  }
  .dropdown-wrapper {
      height: 90px;
  }