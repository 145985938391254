.disabledButton {
    pointer-events: none;
    opacity: 0.5;
}

.ant-table-cell-ellipsis {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    word-break: keep-all;
}