@media(max-width:1250px) {
    .icon-lg {
        width: 64px;
        height: 61px;
        font-size: 18px;
        line-height: 54px;
        top: -19px;
    }

    .card {
        margin-bottom: 10px;
    }

    p.text-sm.mb-0.text-capitalize {
        font-size: 12px;
    }

    .card-footer p.mb-0 {
        font-size: 12px;
    }

    .text-end.pt-1 {
        margin-top: 32px;
    }

}

@media(max-width:1188px) {
    .marginTop {
        margin-top: -17px;
        display: flex;
        justify-content: flex-start !important;
        left: 19%;
        position: inherit !important;
    }

    /* .ant-table.ant-table-empty {
        margin-top: 31px;
    } */
}

@media(max-width:1099px) {

    .main-div-2 {
        grid-template-columns: auto auto;
    }

    .images-sec {

        grid-template-columns: auto auto auto;

    }

    .graph {

        height: auto;

    }


}

@media(max-width:991px) {
    section.ant-layout.new_layout.data {
        margin-left: 0px;
        max-width: 100% !important;


    }

    .angle-btn {
        display: none;
    }

    span.ant-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    aside.ant-layout-sider.ant-layout-sider-dark.sider_left.new {
        max-width: 103px !important;
        width: 103px !important;
        min-width: 98px !important;
    }

    section.ant-layout.new_layout {
        margin-left: 103px;
        max-width: calc(100% - 102px);
    }

    aside.ant-layout-sider.ant-layout-sider-dark.sider_left.new.closedd {
        width: 104px !important;
        display: none;

    }

    .sider_left.new .logo {
        margin: 0;
    }

    .ant-drawer.ant-drawer-right.ant-drawer-open .sider_left {
        display: block !important;

    }

    span.line_dash {
        margin-left: 7px;
        display: none;
    }



    .iocns {
        display: block !important;
        padding-top: 20px;
        padding-left: 13px;
    }

    .ant-drawer.ant-drawer-right.ant-drawer-open {
        display: block !important;
    }

    body.ant-scrolling-effect {
        overflow: hidden !important;
    }

    .card {
        margin-bottom: 28px;
    }

    .notification.button {
        right: 26px;
        top: 22px;

    }

    .right_column {
        width: 100%;
    }

    .right_chat {
        max-width: 100% !important;
        display: unset;
    }

    .wrapper_multi {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 12px;
    }



    svg.svg-inline--fa.fa-bars.fa-w-14.lite-text {
        display: block;
    }

    .main-div {
        /* display: block; */
         margin-top: 115px;
        /* width: 80%; */ 
    }

    .login-form {

        max-width: 404px;
        width: 100%;
    }

    .bg-login-image {
        z-index: 99;
        display: none;
    }

    .main-div-2 {
        grid-template-columns: auto auto;
    }


    .images-sec {

        grid-template-columns: auto;

    }

}

@media(max-width:1100px) {
    .pie-chart {
        display: block;
    }



}

@media(max-width:700px){
    .wrapFlex{
        flex-direction: column;
    }
}

@media(max-width:602px) {

    .main-div-2 {
        grid-template-columns: auto;
    }

    .images-sec {

        grid-template-columns: auto;

    }

}

@media(max-width:480px) {
    .site-layout-background .content-new {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    p.text-sm.mb-0.text-capitalize {
        font-size: 12px;
    }

    .line_true {
        margin: auto 18px;

    }

    .bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3 {
        height: auto;
    }

    .top_button_item {
        padding: 20px 25px 0px 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
        gap: 17px;
    }

    .bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3.system-line {
        display: flex;
        flex-direction: column;
    }

    .bg-login-image img {
        height: 334px;
    }

    .main-div {
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .login-form {
        padding: 20px;
        height: 498px;

        min-width: 303px;

    }

    .ong-event {
        display: block;
    }

    .slick-dots li {
        width: 13px !important;

    }

    .user input {
        width: 100%;
    }

    .login-link a {

        width: 100%;
    }

    .main-div-2 {

        grid-template-columns: auto;
    }

    .images-sec {

        grid-template-columns: auto;

    }

    .input-tex-cat {
        display: block;
    }

    .images-sec img {
        max-width: 154px;
        height: 187px;
    }

}

@media(max-width:480px) {
    .ant-tabs.ant-tabs-top {
        overflow: hidden;
    }
}