.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: linear-gradient(150deg, rgb(0 0 0) 0%, rgb(37 37 37) 33%, rgb(82 82 82) 66%, rgb(0, 0, 0) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */



ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  /* height: 700px; */
  background: #fff;
}

section.ant-layout.ant-layout-has-sider .ant-layout-sider {
  position: fixed;
  min-width: 0;
  transition: unset;
  height: 100%;
  /* overflow: auto !important; */
  /* height: auto; */
}

section.ant-layout.ant-layout-has-sider .ant-menu-item-selected {
  background-color: transparent !important;
  /* background: linear-gradient(180deg, #B4B0F7 0%, #7289D2 100%)!important; */
  border-radius: 3px;
  /* box-shadow: 0 12px 20px -10px rgb(176 174 245), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(122 142 215); */

}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 3px;
}

aside.ant-layout-sider.ant-layout-sider-dark.sider_left.new {
  box-shadow: 0px 4px 20px rgb(2 2 2 / 14%);
  padding: 0 10px;
  overflow-y: scroll !important;
  /* max-width: 319px !important;
  min-width: 319px !important;
  width: 319px !important; */
}

section.ant-layout.ant-layout-has-sider {
  height: 100% !important;
  max-height: 1401px;
  min-height: 100vh;



}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
}

.MuiFormControl-root.css-2m9kme-MuiFormControl-root {
  width: 100%;
  margin: 0;
}

/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent!important;
    background-image: none;
    box-shadow: none;
    border-radius: 4px;
    color: #fff;


  } */
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span,
.ant-layout-sider-children .ant-menu-item-selected span.ant-menu-title-content svg {
  color: #fff;
  opacity: 1;
  transition: all 500ms ease-out;


}

span.ant-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-layout-sider-children ul li span {
  color: #14544A;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}

.ant-layout-sider-children span.ant-menu-title-content svg {
  font-size: 20px;
  /* color: #66615B; */
  transform: scale(0.9);
  width: 22px;


}

.ant-layout-header {
  margin-bottom: 21px;
  height: 64px !important;
  background: #14544A !important;
  box-shadow: 0 0.15rem 0.75rem 0 rgb(93 93 98 / 54%) !important;
}

.logo {
  text-align: center;
}

span.line_dash {
  margin-left: 7px;
}

.ant-layout-sider-children {
  padding-top: 22px;
}

section.ant-layout.new_layout {
  /* margin-left: 251px; */
  /* max-width: calc(100% - 250px); */
  max-width: calc(100% - 315px);
  margin-left: auto;

}

.ant-layout-sider-children {
  padding-top: 15px !important;
}

.card {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgb(204 197 185 / 50%);
  background-color: #FFFFFF;

}

.icon-lg {
  width: 64px;
  height: 64px;
  background: linear-gradient(180deg, #B4B0F7 0%, #7289D2 100%) !important;
  border-radius: 3px;
  box-shadow: 0 12px 20px -10px rgb(176 174 245), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(122 142 215);
  border-radius: 5px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 0;
  top: -19px;
  left: 11px;


}

.text-center.cost {
  text-align: center;
}

p.text-sm.mb-0.text-capitalize {
  margin-bottom: -3px;
}

.card {
  padding: 16px 16px 4px;
}

.card {
  position: relative;
}

.font-weight-bolder {
  font-weight: 600;
  font-size: 15px;
}

.text-end {
  text-align: right !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

h4.mb-0.weight {
  font-weight: 500;
  font-size: 25px;
  color: #344767;

}

.site-layout-background .content-new {
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 29px;
  grid-template-columns: repeat(auto-fit, minmax(30.33%, 1fr));

}

.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(1) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(4) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(7) .icon {
  background-image: linear-gradient(195deg, #ec407a, #d81b60);
}

.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(2) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(4) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(6) .icon {
  background-image: linear-gradient(195deg, #66bb6a, #43a047);
}

.bg-gradient-info {
  background-image: linear-gradient(195deg, #49a3f1, #1a73e8);
}

button.button-list.top_new {
  margin-top: 0;
}

.ant-layout-sider {
  /* background-color: #6ed3f7 !important;
  background-image: linear-gradient(180deg, #76DAFF 0%, #42AAC6 100%)!important; */
  background-color: var(--appTheme) !important;
  background-size: cover;
}

button.ant-drawer-close {
  display: none;
}

.ant-drawer.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  height: auto !important;
}

.ant-layout {
  background: #fff !important;

}

.new_layout {
  background-color: #f0f2f5 !important;
}

.notification.button {
  position: absolute;
  font-size: 14px;
  color: #000;
  right: 40px;
  top: 0px;
}

.d-flexnew_n {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}

.notification.button a.ant-dropdown-trigger.ant-dropdown-link {
  color: #9A9A9A;
  font-size: 14px;
}


button.ant-btn.ant-btn-primary span {
  color: #fff;
}

.ant-tabs-tab-btn:hover {
  color: #1b5449 !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-remove:active {
  color:
    var(--appTheme) !important;
}

.content-new .col-xl-3.col-sm-4.mb-xl-0.mb-3 h3 {
  text-align: center;
}

h4.data_n {
  font-size: 20px;
  font-weight: 600;
  background-color: #ccc;
  margin: 16px 0px;
  box-shadow: 0 0 12px #cacaca;
  padding: 5px 11px;
  border-radius: 5px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-breadcrumb-link svg.svg-inline--fa.fa-home.fa-w-18 {
  /* color: #000; */
}

.ant-btn>span {
  color: white;
}

.ant-btn>span:hover {
  color: #fff;
}

.anticon-left>svg,
.anticon-right>svg {
  color: #fff;
}

.ant-modal-confirm-btns .ant-btn {
  min-width: 60px;
  border-radius: 5px !important;
}

.ant-pagination {
  margin-right: 8px !important;
}

.ant-tabs-tab {
  /* font-size: 16px !important; */
}

.heading-top-desk h1 {
  font-size: 20px !important;
}

.ant-layout-sider-children ul li span {
  /* font-size: 15px !important; */
  font-size: 14.9px !important;
}

.admin-text h2 {
  font-size: 22px !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1b5449 !important;
}

/* .svg-inline--fa {
    color : #00ffd8  !important;
  } */
.ant-layout-sider-children ul li span:hover,
.svg-inline--fa :hover {
  /* color: #fff; */
}

th {
  color: #1b5449 !important;
}


ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark li {
  padding-left: 9px !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark li .line_dash_active {
  padding: 35px;
  border-radius: 0;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.line_dash.active {
  border-radius: 5px;
  padding: 27px 9px 27px 16px;
  background-color: #1b5449 !important;
}

.tablist {
  background-color: #e9e9e9;
}

.ant-layout-sider-children ul li span:hover {
  transition: 0.5s;
  font-weight: 600;
}

.ant-modal-confirm-body .ant-modal-confirm-title,
h1,
h2,
h3,
h4,
h5,
h6,
.wrapper_line label {
  color: #1b5449 !important;
}

input:not([type='color']):not([type='range']):not([type='search']),
select,
textarea,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: solid 1px #1b5449 !important;
  border-radius: 8px !important;
}

input#disable {
  background-color: #ededed !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {

  padding: 27px 16px 27px 16px;
  margin-bottom: 0 !important;
}

.ant-select:not(.ant-select-customize-input):not(.add-question-auto-complete) .ant-select-selector {
  padding: 0 4px !important;
}

.category-accessability.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 3px 18px !important;
}

.ant-select-selection-overflow {
  /* padding: 0 9px !important; */
}

.notActive {
  width: 22px;
  height: 22px;
}

.notActive:hover {
  width: 23px;
  height: 23px;
}

.category-accessability .ant-select-arrow {
  margin-top: -3px !important;
  color: #1b5449 !important;
}

span.ant-select-selection-placeholder {
  color: #757575 !important;
}