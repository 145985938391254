body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Muli', "Helvetica", Arial, sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h6 {
  margin-bottom: 0px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
button {
  background-color: #1b5449  !important;
  /* border-radius: 18px !important; */
}
.ant-pagination-item, .ant-pagination-next .ant-pagination-item-link,.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 7px !important;
}
.ant-modal-close {
  background-color: transparent !important;
  margin-top: 10px !important;
  margin-right: 7px !important; 
}
.ant-modal-close-x{    
    width: 50px !important;
    height: 32px !important;
    line-height: 50px !important; 
}