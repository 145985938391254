.heading-top-desk>h1 {
    padding-top: 5px;
    color: #1b5449;
}

.ant-tabs-nav .ant-tabs-tab-btn {
    font-weight: 600;
}

.ant-tabs-tab-btn[aria-disabled=false] {
    color: #9a9a9a;
}

.referral-form {
    display: flex;
    align-items: baseline;
    justify-content: end;
}

.referral-submit {
    border: 0;
    margin: 0px !important;
    color: #fff !important;
    border-style: solid;
    border-radius: 8px;
    padding: 8px 14px !important;
    width: 109px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.referral-input {
    width: 160px;
    width: 100%;
    padding: 0px 8px 0px 10px;
    background-color: #ffffff;
    border-radius: 13px;
    color: #908373;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    height: 38px;
    box-shadow: none;
    border: 1px solid var(--appTheme);
}

.referral-div {
    margin-right: 20px;
}

.input-referral {
    width: 18% !important;
    margin-right: 7px !important;
}

.referral-error {
    color: red;
    margin-top: 8px;
    font-size: 12px;
    min-height: 10px;
    width: 100%;
}

.referral-heading {
    margin-right: 8px !important;
}